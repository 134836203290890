import CheckBox from '../../../../components/CheckBox';
import { useTranslate } from '../../../../hooks/useTranslate';
import { PROHIBITIONS_KEY } from '../../constants';
import { ProhibitionsProps, ProhibitionValue } from './types';

import { HeadingWithBg } from '../../styled';
import {
  CheckBoxLabel,
  DrugsIcon,
  ExpIcon,
  FoodIcon,
  GunIcon,
  ListItem,
  ListOfProhibitions,
  MoneyIcon,
  PoisonIcon,
  Text,
} from './styled';

export default function Prohibitions({
  isProhibitionsApproval,
  setIsProhibitionsApproval,
}: ProhibitionsProps): JSX.Element {
  const { staticTranslate } = useTranslate();
  const prohibitionsValues: ProhibitionValue[] = [
    {
      image: ExpIcon,
      text: staticTranslate('explosiveFlammableAnd'),
    },
    {
      image: GunIcon,
      text: staticTranslate('anyWeaponFirearmsSignal'),
    },
    {
      image: DrugsIcon,
      text: staticTranslate('drugsPsychotropicSubstances'),
    },
    {
      image: MoneyIcon,
      text: staticTranslate('moneyOfAnyCountry'),
    },
    {
      image: FoodIcon,
      text: staticTranslate('perishableFoodAndBeverages'),
    },
    {
      image: PoisonIcon,
      text: staticTranslate('thereAreAlsoHazardousItems'),
    },
  ];

  return (
    <>
      <HeadingWithBg>{staticTranslate('prohibitedToSend')}</HeadingWithBg>
      <ListOfProhibitions aria-label="Prohibited to send">
        {prohibitionsValues.map(({ text, image: Image }) => (
          <ListItem key={text}>
            <Image />
            <Text>{text}</Text>
          </ListItem>
        ))}
      </ListOfProhibitions>
      <CheckBox
        isChecked={isProhibitionsApproval}
        onChangeState={setIsProhibitionsApproval}
        key={PROHIBITIONS_KEY}
      >
        <CheckBoxLabel>
          {staticTranslate('iDONOTSENDPROHIBITEDGOODS')}
        </CheckBoxLabel>
      </CheckBox>
    </>
  );
}
