import { useParams } from 'react-router-dom';

import { RateType } from '../../helpers/enums';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import DislikeRate from './components/DislikeRate';
import LikeRate from './components/LikeRate';

export default function RatedPage(): JSX.Element {
  const { staticTranslate } = useTranslate();

  const { page } = useParams();

  const isLikeFeedback = page === RateType.like;

  useHeadTitle(staticTranslate('layoutFeedbackScreen'));

  return isLikeFeedback ? <LikeRate /> : <DislikeRate />;
}
