import LoaderMini from '../../../../../../components/LoaderMini';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { FeatureId } from '../../../../types';
import DeviceRatingStars from '../../../DeviceRatingStars';
import { MAX_LENGTH } from '../../../Features/components/Feedbacks/constants';
import { useFeedbacks } from '../../../Features/components/Feedbacks/hooks/useFeedbacks';
import { MAX_HEIGHT } from './constants';

import { NoData, RateWrapper, RatingData } from './styled';

export default function Rate() {
  const { averageRate, feedbacks, status } = useFeedbacks();
  const { staticTranslate } = useTranslate();

  function moveToFeedbacks(): void {
    const feedbacksBtn = document.getElementById(FeatureId.feedbacks);

    if (feedbacksBtn) {
      feedbacksBtn.click();

      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        scrollTo({
          behavior: 'smooth',
          top: MAX_HEIGHT,
        });
      }, 0);
    }
  }

  function choseShownData(): React.ReactNode {
    if (status === 'received') {
      return (
        <>
          <RatingData aria-label="Average rate">{averageRate}</RatingData>
          <DeviceRatingStars rating={averageRate} />
          <RatingData aria-label="Number of comments">
            &#40;{feedbacks.length}
            {feedbacks.length >= MAX_LENGTH && '+'}&#41;
          </RatingData>
        </>
      );
    }

    if (status === 'rejected') {
      return <NoData>{staticTranslate('noData')}</NoData>;
    }

    return <LoaderMini />;
  }

  return (
    <RateWrapper aria-label="Average rate data" onClick={moveToFeedbacks}>
      {choseShownData()}
    </RateWrapper>
  );
}
