import { ChangeEvent, ReactNode, useState } from 'react';

import Box from '../../assets/images/extendBox.png';
import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import { extendOrder } from '../../store/slices/initialSlice';
import RadioButton from './components/RadioButton';
import { DAYS, localizeKey } from './constants';

import { BoxImg, ExtendClock, Form, Heading, ImgWrapper, Main } from './styled';

export default function OrderExtension(): JSX.Element {
  const [extendPeriod, setExtendPeriod] = useState(0);
  const dispatch = useTypedDispatch();
  const { navigateWithUid } = useCustomNavigate();

  const { staticTranslate } = useTranslate();

  const extendHandler = () => {
    dispatch(extendOrder(extendPeriod))
      .unwrap()
      .then(() =>
        navigateWithUid('/order-extension/success', {
          replace: true,
        }),
      )
      .catch(() =>
        navigateWithUid('/order-extension/error', { replace: true }),
      );
  };

  function setRadioButtons(): ReactNode {
    const radioButtons: ReactNode[] = [];

    for (let i = 0; i < DAYS; i++) {
      const day = (i + 1) as keyof typeof localizeKey;
      const checked = day === extendPeriod;
      radioButtons.push(
        <RadioButton
          key={localizeKey[day]}
          label={staticTranslate(localizeKey[day])}
          checked={checked}
          id={localizeKey[day]}
          value={String(day)}
        />,
      );
    }

    return radioButtons;
  }

  useHeadTitle(staticTranslate('orderRenewal'));
  usePageHeaderContext(staticTranslate('extendOrder'));

  function onChangeHandler(event: ChangeEvent<HTMLFormElement>) {
    const { value } = event.target;
    setExtendPeriod(+value);
  }

  return (
    <>
      <Main>
        <ImgWrapper>
          <BoxImg src={Box} alt="box" />
          <ExtendClock role="img" />
        </ImgWrapper>
        <Heading>{staticTranslate('orderExtension2')}</Heading>
        <Form onChange={onChangeHandler} aria-label="Extensions selection">
          {setRadioButtons()}
        </Form>
      </Main>
      <ButtonsPaper>
        <Button variant="outlined" to="/">
          {staticTranslate('main')}
        </Button>
        <Button
          isActive={!!extendPeriod}
          variant="contained"
          onClick={extendHandler}
        >
          {staticTranslate('extend')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
