import { useTranslate } from '../../hooks/useTranslate';
import { OfferCheckboxProps } from './types';

import { CustomLink, Fieldset, Form, Input, Label, Span } from './styled';

export default function OfferCheckbox({
  onChange,
  ...props
}: OfferCheckboxProps): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <Form
      onChange={onChange}
      className="offer-checkbox"
      aria-label="Offer approval check"
      {...props}
    >
      <Fieldset>
        <Label htmlFor="offer">
          <Input type="checkbox" name="offer" id="offer" />
          <Span>
            {staticTranslate('theRulesOfThePublicOffer')}.
            <br />
            <CustomLink to="/offer">
              {staticTranslate('offerContract')}
            </CustomLink>
          </Span>
        </Label>
      </Fieldset>
    </Form>
  );
}
