import { useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import { isProd, projectIs } from '../../../../constants';
import { useTranslate } from '../../../../hooks/useTranslate';
import { selectDeviceInfo } from '../../../../store/slices/initialSlice';

import { ButtonLaundry, ButtonsWrapper } from './styled';

export default function MultiTask(): JSX.Element {
  const { staticTranslate } = useTranslate();
  const { device_uid: DeviceUid } = useSelector(selectDeviceInfo);

  function onSendHandler() {
    const url = isProd
      ? 'https://order-web-form-c2c.omnic.solutions'
      : 'https://dev.order-web-form-c2c.omnic.solutions';
    const href = `${url}/?device_uid=${DeviceUid}`;
    window.open(href, '_self');
  }

  return (
    <ButtonsWrapper>
      {projectIs.omua ? (
        <ButtonLaundry variant="contained" to="/laundry">
          {staticTranslate('laundry')}
        </ButtonLaundry>
      ) : (
        <>
          {!projectIs.omae && (
            <Button variant="contained" to="/get-order">
              {staticTranslate('receive')}
            </Button>
          )}
          <Button variant="contained" to="/return">
            {staticTranslate('return')}
          </Button>
          <Button variant="contained" onClick={onSendHandler}>
            {staticTranslate('send')}
          </Button>
        </>
      )}
    </ButtonsWrapper>
  );
}
