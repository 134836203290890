import { useSelector } from 'react-redux';

import { useTypedDispatch } from '../store';
import { openDeviceCell } from '../store/slices/configSlice';
import { selectUid, setIsReceivedOrder } from '../store/slices/initialSlice';
import { selectScannedData } from '../store/slices/qrSlice';
import { ErrorIds } from '../store/types/errors';
import { useCheckFlow } from './useCheckFlow';
import { useCustomNavigate } from './useCustomNavigate';

interface OpenCellArgs {
  ignoreOpenCells?: boolean;
}

export function useOpenCellHandler() {
  const dispatch = useTypedDispatch();

  const { flow } = useCheckFlow();
  const scannedData = useSelector(selectScannedData);
  const orderUid = useSelector(selectUid);
  const { navigateToErrorPage, navigateWithUid } = useCustomNavigate();

  return (args?: OpenCellArgs) => {
    if (scannedData) {
      dispatch(
        openDeviceCell({
          flow,
          orderUid,
          deviceUid: scannedData,
          ignoreOpenCells: args?.ignoreOpenCells,
        }),
      )
        .unwrap()
        .then(() => dispatch(setIsReceivedOrder()))
        .then(() => navigateWithUid('/open-cell'))
        .catch((error) => {
          if (error.ids?.includes(ErrorIds.thereAreOpenCells)) {
            navigateWithUid('/close-opened-cells');
            return;
          }
          navigateToErrorPage(error.message);
        });
    }
  };
}
