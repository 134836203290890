import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { request } from '../../api';
import { ErrorType } from '../types/errors';
import {
  DeviceAllData,
  DeviceCommonInfo,
  OrderInfo,
  OrderResponse,
  Payment,
} from '../types/orderResponse';
import {
  InitialData,
  InitialParams,
  OrderInitialState,
} from '../types/slicesTypes';
import { RootReducer } from '../types/store';

export const getOrderData = createAsyncThunk<InitialData, InitialParams>(
  '@@order/load-data',
  async ({ uid, resultUrl, description }) => {
    const { data: orderData } = await request<OrderResponse>({
      path: `/order/extending/${uid}/`,
    });

    const isRequestPaymentData = orderData.price;

    const data =
      isRequestPaymentData &&
      (await request<Payment>({
        path: `/order/extending/payload/${uid}/?result_url=${resultUrl}&description=${description}`,
      }));

    const payment = data ? data.data : undefined;

    return {
      ...orderData,
      payment,
    };
  },
);

export const getDeviceData = createAsyncThunk<DeviceAllData, string>(
  '@@device/load-data',
  async (uid) => {
    const { data } = await request<DeviceAllData>({
      path: `/public/device/${uid}/`,
    });

    return data;
  },
);

export const extendOrder = createAsyncThunk<OrderResponse, number>(
  '@@order/extend-order',
  async (extendPeriod, { getState }) => {
    const {
      initialReducer: { savedUid },
    } = getState() as RootReducer;

    const { data } = await request<OrderResponse>({
      method: 'PUT',
      path: `/order/extending/${savedUid}/`,
      data: {
        extending_days: extendPeriod,
      },
    });

    return data;
  },
);

const initialState: OrderInitialState = {
  status: 'idle',
  savedError: null,
  isOrderReceived: false,
  isRequestAvailable: true,
  savedUid: '',
  difference: 0,
  orderInfo: null,
  deviceInfo: null,
  supportPhone: '',
  payment: undefined,
};

const initialSlice = createSlice({
  name: '@@order',
  initialState,
  reducers: {
    setOrderUid: (state, { payload }: PayloadAction<string>) => {
      state.savedUid = payload;
    },
    setIsReceivedOrder: (state) => {
      state.isOrderReceived = true;
    },
    setIsRequestAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.isRequestAvailable = payload;
    },
    setDeviceStatus: (state, { payload }: PayloadAction<boolean>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.deviceInfo!.is_online = payload;
    },
    setSavedError: (state, { payload }: PayloadAction<ErrorType | null>) => {
      state.savedError = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderData.fulfilled, (state, { payload: data }) => {
        const {
          payment,
          device_info: deviceInfo,
          support_phone: supportPhone,
          ...orderInfo
        } = data;

        const { description, type, ...otherData } = deviceInfo;

        state.orderInfo = orderInfo;
        state.supportPhone = supportPhone;
        state.difference = data.price - data.balance;
        state.payment = payment;

        state.deviceInfo = {
          device_description: description,
          device_type: type,
          ...otherData,
        };
        state.status = 'received';
      })
      .addCase(extendOrder.fulfilled, (state, { payload }) => {
        state.orderInfo = payload as OrderResponse;
        state.status = 'received';
      })
      .addCase(getDeviceData.fulfilled, (state, { payload }) => {
        const {
          device,
          name,
          type,
          description,
          uid,
          location,
          support_phone: supportPhone,
          ...otherMainDeviceData
        } = payload;

        const {
          name: locationName,
          uid: locationUid,
          ...otherLocationData
        } = location;

        state.supportPhone = supportPhone;

        state.deviceInfo = {
          device_id: device,
          device_name: name,
          device_type: type,
          device_description: description,
          device_uid: uid,
          location_name: locationName,
          location_uid: locationUid,
          ...otherMainDeviceData,
          ...otherLocationData,
        };
        state.status = 'received';
      })
      .addCase(getOrderData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDeviceData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(extendOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrderData.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(getDeviceData.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(extendOrder.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const initialReducer = initialSlice.reducer;

export const {
  setIsReceivedOrder,
  setIsRequestAvailable,
  setSavedError,
  setOrderUid,
  setDeviceStatus,
} = initialSlice.actions;

export const selectIsOrderReceived = ({
  initialReducer: { isOrderReceived },
}: RootReducer) => isOrderReceived;

export const selectOrderInfo = ({
  initialReducer: { orderInfo },
}: RootReducer) => orderInfo as OrderInfo;

export const selectUid = ({ initialReducer: { savedUid } }: RootReducer) =>
  savedUid;

export const selectDifference = ({
  initialReducer: { difference },
}: RootReducer) => difference;

export const selectPayment = ({ initialReducer: { payment } }: RootReducer) =>
  payment;

export const selectDeviceInfo = ({
  initialReducer: { deviceInfo },
}: RootReducer) => deviceInfo as DeviceCommonInfo;

export const selectRequestAvailability = ({
  initialReducer: { isRequestAvailable },
}: RootReducer) => isRequestAvailable;

export const selectSavedError = ({
  initialReducer: { savedError },
}: RootReducer) => savedError;

export const selectExpirationDate = ({
  initialReducer: { orderInfo },
}: RootReducer) => {
  const { expiration_date_unix: expirationDateUnix } = orderInfo as OrderInfo;
  return expirationDateUnix * 1000;
};

export const selectResponseStatus = ({
  initialReducer: { status },
}: RootReducer) => status;

export const selectSupportPhone = ({
  initialReducer: { supportPhone },
}: RootReducer) => supportPhone;

export const selectClients = ({
  initialReducer: { orderInfo },
}: RootReducer) => {
  const {
    recipient_name: recipientName,
    recipient_phone: recipientPhone,
    sender_name: senderName,
    sender_phone: senderPhone,
  } = orderInfo as OrderInfo;
  return {
    recipientName,
    recipientPhone,
    senderName,
    senderPhone,
  };
};
