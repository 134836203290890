import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import PhoneInput from '../../components/PhoneInput';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import { selectPhone, setPhone } from '../../store/slices/receiveSlice';
import CodeContainer from './components/CodeContainer';
import { useButtonsSettings } from './hooks/getButtonsData';
import { useReceiveFlowManager } from './hooks/useReceiveFlowManager';

import {
  ContainersPaper,
  ContainersWrapper,
  ContentWrapper,
  Text,
} from './styled';

export default function GetOrderPage(): JSX.Element {
  const phone = useSelector(selectPhone);
  const dispatch = useTypedDispatch();

  const { staticTranslate } = useTranslate();

  function setPhoneNumber(phoneNumber: string) {
    dispatch(setPhone(phoneNumber));
  }

  const {
    step,
    countdownValue,
    isGettingCode,
    isGettingOrders,
    getCodeHandler,
    getOrdersListHandler,
    prevStepHandle,
  } = useReceiveFlowManager();

  const {
    backBtnText,
    backBtnVariant,
    getCodeValue,
    isActiveGetCode,
    isShowGetOrders,
    isActiveGetOrders,
  } = useButtonsSettings({
    step,
    countdownValue,
  });

  useHeadTitle(staticTranslate('orderCollection'));
  usePageHeaderContext(staticTranslate('orderCollection'));

  return (
    <>
      <ContainersWrapper>
        <ContainersPaper step={step}>
          <ContentWrapper>
            <Text>{staticTranslate('enterYourPhoneNumberAndGet')}</Text>
            <PhoneInput value={phone} onChange={setPhoneNumber} />
          </ContentWrapper>
          <ContentWrapper>
            <CodeContainer phone={phone} countdownValue={countdownValue} />
          </ContentWrapper>
        </ContainersPaper>
      </ContainersWrapper>
      <ButtonsPaper>
        {isShowGetOrders && (
          <Button
            isActive={isActiveGetOrders}
            variant="contained"
            onClick={getOrdersListHandler}
            isLoading={isGettingOrders}
          >
            {staticTranslate('getListOrders')}
          </Button>
        )}
        <Button
          isActive={isActiveGetCode}
          variant="contained"
          onClick={getCodeHandler}
          isLoading={isGettingCode}
        >
          {getCodeValue}
        </Button>
        <Button variant={backBtnVariant} onClick={prevStepHandle}>
          {backBtnText}
        </Button>
      </ButtonsPaper>
    </>
  );
}
