import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import { setParams } from '../../../../store/slices/returnSlice';
import { Params } from '../../../../store/types/cellsResponse';
import { useCells } from './hooks/useCells';
import { ParcelSizePops } from './types';

import { HeadingWithBg } from '../../styled';
import {
  CellBox,
  CellInfoWrapper,
  CellParams,
  CellsContainer,
  CellSize,
  SVGWrapper,
} from './styled';

export default function ParcelSize({
  changeStep,
}: ParcelSizePops): JSX.Element {
  const cells = useCells();
  const dispatch = useTypedDispatch();
  const { staticTranslate } = useTranslate();

  const selectParams = (params: Params) => {
    dispatch(setParams(params));
    changeStep('next');
  };

  return (
    <>
      <HeadingWithBg>{staticTranslate('selectTheParcelSize')}</HeadingWithBg>
      <CellsContainer>
        {cells.map((cell) => {
          const {
            has_empty: hasEmpty,
            image: Image,
            size,
            type,
            params,
          } = cell;

          const { height, width } = params;
          const shownParams = `${height}x${width}`;

          return (
            <CellBox
              aria-label={`Select the parcel size ${shownParams}`}
              role="button"
              key={type}
              isUnavailable={!hasEmpty}
              onClick={() => hasEmpty && selectParams(params)}
            >
              <SVGWrapper>
                <Image role="img" />
              </SVGWrapper>
              <CellInfoWrapper>
                <CellSize>{size}</CellSize>
                <CellParams>{shownParams}</CellParams>
              </CellInfoWrapper>
            </CellBox>
          );
        })}
      </CellsContainer>
    </>
  );
}
