import styled, { css } from 'styled-components';

import { CellPropsCommon } from '../../types';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

function applyScreen({ isScreen }: CellPropsCommon) {
  return (
    isScreen &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 92%;
        height: 82%;
        background: linear-gradient(
          179.35deg,
          rgba(161, 193, 203, 0.4) 0.56%,
          rgba(236, 236, 236, 0.4) 99.44%
        );
      }
    `
  );
}

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  ${applyScreen};
  ${stackCellCommon};

  &:first-child {
    grid-column: 1 / 3;
    grid-template: 1;
  }

  &:last-child {
    grid-column: 1 / 3;
    grid-template: 10;
  }
`;

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: grid;
  column-gap: 1px;
  row-gap: 2px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20% 12% repeat(3, 9%) repeat(4, 6.5%) auto;
`;
