import { projectIs } from '../../constants';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';

import {
  ColorMessage,
  DefaultLogo,
  ImageDefault,
  ImageOmis,
  LandscapeContainer,
  Message,
  OmisLogo,
  TextWrapper,
} from './styled';

export default function LandScape(): JSX.Element {
  const { staticTranslate } = useTranslate();

  useHeadTitle();

  const image =
    projectIs.omkaz || projectIs.omar || projectIs.mncdk ? (
      <ImageDefault role="img" />
    ) : (
      <ImageOmis role="img" />
    );

  const logo =
    projectIs.omkaz || projectIs.omar || projectIs.mncdk ? (
      <DefaultLogo role="img" />
    ) : (
      <OmisLogo role="img" />
    );

  return (
    <LandscapeContainer>
      {image}
      <TextWrapper>
        {logo}
        <Message>{staticTranslate('landscapeTitle')}</Message>
        <ColorMessage>{staticTranslate('landscapeDescription')}</ColorMessage>
      </TextWrapper>
    </LandscapeContainer>
  );
}
