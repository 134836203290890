import { useSelector } from 'react-redux';

import IndicatorDot from '../../../../../../components/IndicatorDot/IndicatorDot';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { selectDeviceInfo } from '../../../../../../store/slices/initialSlice';

import { DeviceStatusWrapper, Status, StatusText } from './styled';

export default function DeviceStatus() {
  const deviceInfo = useSelector(selectDeviceInfo);
  const { staticTranslate } = useTranslate();

  const availabilityText = deviceInfo.is_online
    ? staticTranslate('available')
    : staticTranslate('notAvailable');

  const availabilityStatus = deviceInfo.is_online;

  const cellsInfoText = deviceInfo.has_empty
    ? staticTranslate('emptyCells')
    : staticTranslate('noEmptyCells');

  return (
    <DeviceStatusWrapper>
      <Status>
        <IndicatorDot isAvailable={availabilityStatus} />
        <StatusText aria-label="Information about device">
          {availabilityText}
        </StatusText>
      </Status>
      <Status className="device-info__online__isCell">
        <IndicatorDot isAvailable={deviceInfo.has_empty} />
        <StatusText aria-label="Information about cells">
          {cellsInfoText}
        </StatusText>
      </Status>
    </DeviceStatusWrapper>
  );
}
