import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { useTranslate } from '../../hooks/useTranslate';
import { InfoTemplateProps } from './types';

import { ButtonWrapper, Container, Main } from './styled';

export default function InfoPageTemplate({
  children,
}: InfoTemplateProps): JSX.Element {
  const { staticTranslate } = useTranslate();

  const navigate = useNavigate();

  return (
    <Container>
      <Main>{children}</Main>
      <ButtonWrapper>
        <Button variant="contained" onClick={() => navigate(-1)}>
          {staticTranslate('back')}
        </Button>
      </ButtonWrapper>
    </Container>
  );
}
