import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../../../../../components/Button';
import TextArea from '../../../../../../../../components/TextArea';
import { useSendFeedback } from '../../../../../../../../hooks/useSendFeedback';
import { useTranslate } from '../../../../../../../../hooks/useTranslate';
import { selectOrderInfo } from '../../../../../../../../store/slices/initialSlice';
import { formattingString } from '../../../../../../../../utils/formattingString';
import { FeedbackType } from '../../../../../../../types/feedback';
import DeviceRatingStars from '../../../../../DeviceRatingStars';

import { ButtonWrapper, StarsButton, UserName } from './styled';

export default function FeedbackForm() {
  const [textAreaText, setTextAreaText] = useState('');
  const [rate, setRate] = useState(0);

  const orderInfo = useSelector(selectOrderInfo);

  const { staticTranslate } = useTranslate();
  const sendFeedback = useSendFeedback();

  const setStarRate = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    setRate(+target.id);
  };

  const sendFeedbackHandler = async () => {
    setTextAreaText('');
    await sendFeedback({
      type: FeedbackType.postamat,
      rate,
      comment: formattingString(textAreaText),
    });
  };

  return (
    <>
      {orderInfo.recipient_name && (
        <UserName aria-label="User name">{orderInfo.recipient_name}</UserName>
      )}
      <StarsButton
        role="button"
        aria-label="Set rate button"
        onClick={setStarRate}
      >
        <DeviceRatingStars rating={rate} />
      </StarsButton>
      <TextArea
        setTextAreaText={setTextAreaText}
        textAreaText={textAreaText}
        placeholder={staticTranslate('feedbackLike5')}
      />
      <ButtonWrapper>
        <Button
          isActive={!!rate}
          variant="contained"
          size="l"
          onClick={sendFeedbackHandler}
        >
          {staticTranslate('leaveReview')}
        </Button>
      </ButtonWrapper>
    </>
  );
}
