import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import { Images } from '../../components/Images';
import MapManager from '../../components/MapManager';
import { isProd, projectIs } from '../../constants';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useCheckFlow } from '../../hooks/useCheckFlow';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useResponseInfo } from '../../hooks/useResponseInfo';
import { useTranslate } from '../../hooks/useTranslate';
import {
  selectDeviceInfo,
  selectOrderInfo,
} from '../../store/slices/initialSlice';
import { OrderInfo as OrderInfoType } from '../../store/types/orderResponse';
import { Flow } from '../../types/appLogic';
import MultiTask from './components/MultiTask';
import OrderInfo from './components/OrderInfo';
import PostamatInfo from './components/PostamatInfo';

import { MainPaper, MapWrapper, PostamatPhoto, TopDataWrapper } from './styled';

export default function MainPage(): JSX.Element {
  const deviceInfo = useSelector(selectDeviceInfo);
  const orderInfo: OrderInfoType | null = useSelector(selectOrderInfo);
  const { isOrderInfoAvailable } = useResponseInfo();

  const { staticTranslate } = useTranslate();

  const { flow } = useCheckFlow();

  useHeadTitle();

  const mainPageHeader = orderInfo?.order_number
    ? `${staticTranslate('orderNumber')} ${orderInfo?.order_number}`
    : '';
  usePageHeaderContext(mainPageHeader);

  const isShowOrderInfo = isOrderInfoAvailable && flow === Flow.common;
  const isShowMultiTask =
    (!isProd && (projectIs.omge || projectIs.omit || projectIs.omth)) ||
    (!projectIs.omth && !projectIs.omge && !projectIs.omit && !projectIs.omis);

  const shownButton =
    flow === Flow.common ? (
      <Button variant="contained" to="/order-receive/qr-scan">
        {staticTranslate('receive')}
      </Button>
    ) : (
      isShowMultiTask && <MultiTask />
    );

  return (
    <>
      <MainPaper>
        <TopDataWrapper>
          <MapWrapper>
            <MapManager width="100%" height="100%" />
          </MapWrapper>
          <PostamatPhoto
            loading="lazy"
            src={
              deviceInfo.images?.length
                ? deviceInfo.images[0]
                : Images.PostamatTest.Image1
            }
            alt="Postamat photo"
          />
        </TopDataWrapper>
        <PostamatInfo />
        {isShowOrderInfo && <OrderInfo />}
      </MainPaper>
      {shownButton}
    </>
  );
}
