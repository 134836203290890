import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteObject, useRoutes as useBaseRoutes } from 'react-router-dom';

import AppWrapper from '../components/AppWrapper';
import A11yPage from '../layouts/A11yPage';
import A11yStatement from '../layouts/A11yStatement';
import CloseOpenedCells from '../layouts/CloseOpenedCells/CloseOpenedCells';
import DeviceInfo from '../layouts/DeviceInfo';
import GetOrderPage from '../layouts/GetOrderPage';
import Clothes from '../layouts/Laundry/components/Clothes';
import LaundryOffer from '../layouts/Laundry/components/LaundryOffer';
import LaundryMainPage from '../layouts/Laundry/components/MainPage';
import OpenCellViewer from '../layouts/Laundry/components/OpenCellViewer';
import PackagingRules from '../layouts/Laundry/components/PackagingRules';
import PersonalData from '../layouts/Laundry/components/PersonalData';
import WhatsNext from '../layouts/Laundry/components/WhatsNext';
import MainPage from '../layouts/MainPage';
import OpenCell from '../layouts/OpenCell';
import OrderExtension from '../layouts/OrderExtension';
import ExtendError from '../layouts/OrderExtension/components/ExtendError';
import ExtendSuccess from '../layouts/OrderExtension/components/ExtendSuccess';
import OrdersListPage from '../layouts/OrdersListPage';
import PaymentPage from '../layouts/PaymentPage';
import PaymentResult from '../layouts/PaymentResult';
import QrScan from '../layouts/QrScan';
import RatedPage from '../layouts/RatedPage';
import DetailRate from '../layouts/RatedPage/components/DetailRate';
import ReturnForm from '../layouts/ReturnForm';
import ReturnPage from '../layouts/ReturnPage/ReturnPage';
import ToOpenCell from '../layouts/ToOpenCell';
import { useTypedDispatch } from '../store';
import { selectAppFlow, setAppFlow } from '../store/slices/flowSlice';
import { useCheckFlow } from './useCheckFlow';
import { useResponseInfo } from './useResponseInfo';

export function useRoutes() {
  const dispatch = useTypedDispatch();
  const appFlow = useSelector(selectAppFlow);

  const { isOrderInfoAvailable } = useResponseInfo();

  const { basePath, flow } = useCheckFlow();

  useEffect(() => {
    if (!appFlow) dispatch(setAppFlow(flow));
  }, [dispatch, appFlow, flow]);

  const laundryRoutes: RouteObject[] = [
    {
      path: 'personal-data',
      element: <PersonalData />,
    },
    {
      path: 'clothes',
      element: <Clothes />,
    },
    {
      path: 'packaging-rules',
      element: <PackagingRules />,
    },
    {
      path: 'laundry-offer',
      element: <LaundryOffer />,
    },
    {
      path: 'open-cell-viewer',
      element: <OpenCellViewer />,
    },
    {
      path: 'whats-next',
      element: <WhatsNext />,
    },
  ];

  const orderRoutes: RouteObject[] = isOrderInfoAvailable
    ? [
        {
          path: 'order-receive',
          children: [
            {
              path: 'qr-scan',
              element: <QrScan />,
            },
            {
              path: 'to-open-cell',
              element: <ToOpenCell />,
            },
          ],
        },
        {
          path: 'order-extension',
          children: [
            {
              index: true,
              element: <OrderExtension />,
            },
            {
              path: 'success',
              element: <ExtendSuccess />,
            },
            {
              path: 'error',
              element: <ExtendError />,
            },
          ],
        },
        {
          path: 'order-payment',
          children: [
            {
              index: true,
              element: <PaymentPage />,
            },
            {
              path: 'result/:status',
              element: <PaymentResult />,
            },
          ],
        },
      ]
    : [];

  return useBaseRoutes([
    {
      path: `${basePath}/:uid/*`,
      element: <AppWrapper />,
      children: [
        {
          index: true,
          element: <MainPage />,
        },
        {
          path: 'device-info',
          element: <DeviceInfo />,
        },
        {
          path: 'rate',
          children: [
            {
              path: ':page',
              element: <RatedPage />,
            },
            {
              path: 'detail-rate',
              element: <DetailRate />,
            },
          ],
        },
        {
          path: 'get-order',
          element: <GetOrderPage />,
        },
        {
          path: 'orders-list',
          element: <OrdersListPage />,
        },
        {
          path: 'return',
          children: [
            {
              index: true,
              element: <ReturnPage />,
            },
            {
              path: 'return-form',
              element: <ReturnForm />,
            },
          ],
        },
        {
          path: 'a11y-features',
          element: <A11yPage />,
        },
        {
          path: 'a11y-statement',
          element: <A11yStatement />,
        },
        {
          path: 'close-opened-cells',
          element: <CloseOpenedCells />,
        },
        {
          path: 'open-cell',
          element: <OpenCell />,
        },
        {
          path: 'laundry',
          children: [
            {
              index: true,
              element: <LaundryMainPage />,
            },
            ...laundryRoutes,
          ],
        },
        ...orderRoutes,
      ],
    },
  ]);
}
