import { StackProps } from '../../types';

import { SizeContainer, StackBody, StackWrapper } from '../../styled';
import { Cell, CellsContainer } from './styled';

export default function BhVending({
  cells,
  isActiveStack,
  openedCells,
  isError,
}: StackProps): JSX.Element {
  return (
    <SizeContainer role="presentation" aria-label="Postamat">
      <StackWrapper>
        <StackBody>
          <CellsContainer>
            {cells.map(({ cell, row, screen }) => {
              const isActiveCell =
                isActiveStack && !!openedCells?.find((c) => c.cell === cell);
              const ariaLabel = isActiveCell
                ? 'Cell with your order'
                : 'Postamat cell';

              return (
                <Cell
                  isScreen={screen}
                  key={row + cell}
                  isActiveCell={isActiveCell}
                  aria-label={ariaLabel}
                  $isError={isError}
                />
              );
            })}
          </CellsContainer>
        </StackBody>
      </StackWrapper>
    </SizeContainer>
  );
}
