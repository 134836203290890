import step1img from '../../../../assets/images/return/step1.png';
import { useTranslate } from '../../../../hooks/useTranslate';

import {
  ContentWrapper,
  Heading,
  Image,
  ListItem,
  OrderedList,
} from '../../styled';
import { AttentionBlock, AttentionIcon, AttentionText } from './styled';

export default function ReturnPolicy(): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <>
      <Image src={step1img} />
      <ContentWrapper>
        <Heading>{staticTranslate('returnPolicyTerminalX')}</Heading>
        <OrderedList>
          <ListItem>{staticTranslate('youMusthavePurchasedThe')}</ListItem>
          <ListItem>{staticTranslate('lessThan14DaysMustHave')}</ListItem>
          <ListItem>{staticTranslate('aReturnFormMustBeAttached')}</ListItem>
        </OrderedList>
        <AttentionBlock>
          <AttentionIcon />
          <AttentionText>
            {`${staticTranslate('damagedGoods')} ${staticTranslate(
              'willNotBeAcceptedForReturn',
            )}`}
          </AttentionText>
        </AttentionBlock>
      </ContentWrapper>
    </>
  );
}
