import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNotifications } from '../../../context/NotificationsContext';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../store';
import {
  cleanData,
  getCode,
  getOrdersList,
  selectCodeStatus,
  selectOrdersListStatus,
} from '../../../store/slices/receiveSlice';
import { ReceiveFlowManagerData, Step } from '../types';
import { useCountdown } from './useCountdown';

export function useReceiveFlowManager(): ReceiveFlowManagerData {
  const [step, setStep] = useState(Step.phone);
  const dispatch = useTypedDispatch();

  const countdownValue = useCountdown();

  const { setNotification } = useNotifications();
  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();

  const ordersListStatus = useSelector(selectOrdersListStatus);
  const codeStatus = useSelector(selectCodeStatus);

  useEffect(() => {
    if (codeStatus === 'received') setStep(Step.code);
    else setStep(Step.phone);
  }, [codeStatus]);

  function getCodeHandler(): void {
    dispatch(getCode())
      .unwrap()
      .catch(() => {
        setNotification({
          status: 'error',
          value: staticTranslate('noOrdersFound'),
        });
      });
  }

  function getOrdersListHandler(): void {
    dispatch(getOrdersList())
      .unwrap()
      .then(() => {
        dispatch(cleanData());
        navigateWithUid('/orders-list', { replace: true });
      })
      .catch(() =>
        setNotification({
          status: 'error',
          value: staticTranslate('invalidConfirmationCode'),
        }),
      );
  }

  function prevStepHandle(): void {
    if (step === Step.code) {
      dispatch(cleanData());
    } else navigateWithUid('/');
  }

  return {
    step,
    countdownValue,
    isGettingCode: codeStatus === 'loading',
    isGettingOrders: ordersListStatus === 'loading',
    getCodeHandler,
    getOrdersListHandler,
    prevStepHandle,
  };
}
