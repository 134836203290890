import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { request } from '../api';
import {
  selectDeviceInfo,
  setDeviceStatus,
} from '../store/slices/initialSlice';

interface DeviceStatusRequest {
  online: boolean;
}

const FIRST_TIMEOUT_TO_REQUEST = 10000;
const TIMEOUT_TO_REQUEST_OFFLINE = 2000;
const TIMEOUT_TO_REQUEST_ONLINE = 15000;

let timeoutToRequest = FIRST_TIMEOUT_TO_REQUEST;

export default function useDeviceStatus() {
  const [status, setStatus] = useState(false);

  const deviceInfo = useSelector(selectDeviceInfo);
  const dispatch = useDispatch();

  const deviceID = deviceInfo?.device_id;

  const getDeviceStatusHandler = useCallback(async () => {
    if (!deviceID) return;

    const isDeviseOnline = await request<DeviceStatusRequest>({
      path: `public/device/status/${deviceID}/`,
      method: 'GET',
    }).then(({ data }) => {
      setStatus(data.online);
      dispatch(setDeviceStatus(data.online));

      return data.online;
    });

    timeoutToRequest = isDeviseOnline
      ? TIMEOUT_TO_REQUEST_ONLINE
      : TIMEOUT_TO_REQUEST_OFFLINE;

    const timeout = setTimeout(() => {
      document.removeEventListener('visibilitychange', () => {
        const isTabVisible = document.visibilityState === 'visible';
        if (!isTabVisible) clearTimeout(timeout);
      });
      getDeviceStatusHandler();
    }, timeoutToRequest);

    document.addEventListener('visibilitychange', () => {
      const isTabVisible = document.visibilityState === 'visible';
      if (!isTabVisible) clearTimeout(timeout);
    });
  }, [deviceID, dispatch]);

  useEffect(() => {
    getDeviceStatusHandler();

    function fetchDeviceStatus() {
      const isTabVisible = document.visibilityState === 'visible';
      if (isTabVisible && deviceInfo?.device_id) getDeviceStatusHandler();
    }

    document.addEventListener('visibilitychange', () => fetchDeviceStatus());

    return () => {
      document.removeEventListener('visibilitychange', () =>
        fetchDeviceStatus(),
      );
    };
  }, [deviceInfo?.device_id, getDeviceStatusHandler]);

  return { status };
}
