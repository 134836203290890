import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import CheckBox from '../../../../components/CheckBox';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../hooks/useTranslate';
import { selectClothes } from '../../../../store/slices/laundrySlice';
import { useOpenCell } from './hooks/useOpenCell';

import { Title } from '../../styled';
import {
  BottomContainer,
  CheckboxLabel,
  CustomLink,
  PutClothesImg,
  RulesLi,
  RulesOl,
  Text,
  TightenSealImg,
  TopWrapper,
} from './styled';

export default function PackagingRules(): JSX.Element {
  const [isApproval, setIsApproval] = useState(false);
  const { openCell, isProcessing } = useOpenCell();

  const { staticTranslate } = useTranslate();
  const { navigateWithUid } = useCustomNavigate();
  const clothes = useSelector(selectClothes);

  useEffect(() => {
    if (!clothes.length) {
      navigateWithUid('/laundry/clothes', { replace: true });
    }
    // check once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopWrapper>
        <Title>{staticTranslate('packagingRules')}</Title>
        <RulesOl>
          <RulesLi>
            <Text>{staticTranslate('putClothesIntoBag')}:</Text>
            <PutClothesImg role="img" aria-label="Pack your clothes" />
          </RulesLi>
          <RulesLi>
            <Text>{staticTranslate('tieBag')}:</Text>
            <TightenSealImg
              role="img"
              aria-label="Tie the bag and tighten the seal"
            />
          </RulesLi>
        </RulesOl>
      </TopWrapper>
      <BottomContainer>
        <CheckBox isChecked={isApproval} onChangeState={setIsApproval}>
          <CheckboxLabel>
            {staticTranslate('publicOfferAcceptance')}{' '}
            <CustomLink to="/laundry/laundry-offer">
              {staticTranslate('publicOffer')}
            </CustomLink>
          </CheckboxLabel>
        </CheckBox>
        <Button
          isActive={isApproval}
          isLoading={isProcessing}
          variant="contained"
          onClick={openCell}
        >
          {staticTranslate('openCell')}
        </Button>
      </BottomContainer>
    </>
  );
}
