import { useSelector } from 'react-redux';

import {
  selectConfig,
  selectOpenedCellData,
} from '../../store/slices/configSlice';
import Loader from '../Loader';
import CombineStacks from './components/CombineStacks/CombineStacks';
import UltraCommon from './components/UltraCommon';
import { useGetDeviceConfig } from './hooks/useGetDeviceConfig';
import { MOCKED_CELLS } from './mocks/cells';

import {
  PostamatContainer,
  PostamatHead,
  PostamatWrapper,
  StacksContainer,
} from './styled';

export default function Postamat(): JSX.Element {
  const deviceConfig = useSelector(selectConfig);
  const configStatus = useGetDeviceConfig();

  const openedCellData = useSelector(selectOpenedCellData);
  let isError = false;

  function setOpenedCells() {
    if (!openedCellData) return;

    if (openedCellData.opened_cells?.length) {
      isError = true;
      return openedCellData.opened_cells;
    }

    if (
      Number.isInteger(openedCellData.cell) &&
      Number.isInteger(openedCellData.stack)
    ) {
      return [{ cell: openedCellData.cell, stack: openedCellData.stack }];
    }
  }

  const openedCells = setOpenedCells();

  const isShowHighlightCell = !!deviceConfig && !!openedCells;

  const shownData = isShowHighlightCell ? (
    deviceConfig.stacks.map(({ cells, stack: stackId, name }) => {
      const currentStackOpenedCells = openedCells.filter(
        ({ stack }) => stack === stackId,
      );
      const isActiveStack = !!currentStackOpenedCells.length;

      const isSkipTemplate = cells.length <= 1;

      if (isSkipTemplate) return;

      return (
        <CombineStacks
          stackId={stackId}
          name={name}
          cells={cells}
          openedCells={currentStackOpenedCells}
          isActiveStack={isActiveStack}
          isError={isError}
        />
      );
    })
  ) : (
    <UltraCommon isActiveStack={false} cells={MOCKED_CELLS} />
  );

  return configStatus === 'loading' ? (
    <Loader />
  ) : (
    <PostamatContainer>
      <PostamatWrapper>
        <PostamatHead />
        <StacksContainer>{shownData}</StacksContainer>
      </PostamatWrapper>
    </PostamatContainer>
  );
}
