import Button from '../../components/Button';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { useErrorPageSettings } from './hooks/useErrorPageSettings';

import {
  BtnWithDataWrapper,
  Difference,
  ErrorContainer,
  ErrorMessage,
  ErrorTitle,
  LegalInfoWrapper,
  LegalLink,
  LegalText,
  Main,
  Number,
  PhoneContainer,
  PhoneNumber,
  PhoneText,
  VisualWrapper,
} from './styled';

export default function ErrorScreen(): JSX.Element {
  const {
    difference,
    errorPageData,
    supportPhone,
    isShowButton,
    isShowDifference,
    isShowSupportPhone,
    isShowLegalInfo,
  } = useErrorPageSettings();

  const { staticTranslate } = useTranslate();

  useHeadTitle(errorPageData.title);

  // Нужна доработка на беке, добавить валюту.

  return (
    <ErrorContainer>
      <Main>
        <ErrorTitle aria-label="Error reason">{errorPageData.title}</ErrorTitle>
        <ErrorMessage aria-label="Error description">
          {errorPageData.description}
        </ErrorMessage>
      </Main>
      <VisualWrapper>
        {isShowDifference && (
          <Difference aria-label="Sum to pay">{difference}</Difference>
        )}
        {errorPageData.image}
      </VisualWrapper>
      <BtnWithDataWrapper>
        {isShowSupportPhone && (
          <PhoneContainer>
            <PhoneText>{staticTranslate('supportNumber')}</PhoneText>
            <Number
              aria-label="Support number"
              href={`tel: ${supportPhone}`}
              className="error-container__support__phone"
            >
              {supportPhone}
            </Number>
          </PhoneContainer>
        )}
        {isShowLegalInfo && (
          <LegalInfoWrapper aria-label="Legal info">
            <LegalText aria-label="Legal address">
              {staticTranslate('republicOfKazakhstan')}
            </LegalText>
            <PhoneNumber href="tel:+78000040010" aria-label="Legal number">
              +7-800-004-0010
            </PhoneNumber>
            <LegalLink to="/offer">
              {staticTranslate('offerContract')}
            </LegalLink>
          </LegalInfoWrapper>
        )}
        {isShowButton && (
          <Button variant="outlined" to="/">
            {staticTranslate('main')}
          </Button>
        )}
      </BtnWithDataWrapper>
    </ErrorContainer>
  );
}
