import { useTranslations } from '@omnipkg/ui-kit-web';

import { useDynamicTranslate } from './useDynamicTranslate';

interface Translate {
  staticTranslate: (value: string) => string;
  dynamicTranslate: (value: string) => string;
}

export function useTranslate(): Translate {
  const { t } = useTranslations();

  return {
    staticTranslate: (value: string) => t(value),
    dynamicTranslate: useDynamicTranslate,
  };
}
