import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../../../../../components/Loader';
import { useResponseInfo } from '../../../../../../hooks/useResponseInfo';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { selectFeedbacksStatus } from '../../../../../../store/slices/feedbackSlice';
import FeedbackForm from './components/FeedbackForm';
import LastFeedback from './components/LastFeedback';
import { MAX_LENGTH } from './constants';
import { useFeedbacks } from './hooks/useFeedbacks';

import {
  AverageRate,
  AverageRatingWrapper,
  BackLine,
  ColorLine,
  ErrorMessage,
  FeedbacksCount,
  FeedbacksWrapper,
  LoaderWrapper,
  Number,
  NumberLineGroup,
  RatingLinesGroup,
  RatingTitle,
} from './styled';

export default function Feedbacks(): JSX.Element {
  const { shownFeedback, percents, averageRate, feedbacks } = useFeedbacks();
  const { staticTranslate } = useTranslate();

  const { isOrderInfoAvailable } = useResponseInfo();

  const status = useSelector(selectFeedbacksStatus);

  const feedbacksLengthString = `${staticTranslate('feedbacks')} (${
    feedbacks.length
  }${feedbacks.length >= MAX_LENGTH ? '+' : ''})`;

  function choseShownData(): ReactNode {
    if (status === 'received') {
      return (
        <>
          <RatingTitle>{staticTranslate('reviewsAverage')}</RatingTitle>
          <AverageRatingWrapper>
            <RatingLinesGroup>
              <NumberLineGroup>
                <Number>5</Number>
                <BackLine>
                  <ColorLine
                    style={{
                      width: percents[5],
                    }}
                  />
                </BackLine>
              </NumberLineGroup>
              <NumberLineGroup>
                <Number>4</Number>
                <BackLine>
                  <ColorLine
                    style={{
                      width: percents[4],
                    }}
                  />
                </BackLine>
              </NumberLineGroup>
              <NumberLineGroup>
                <Number>3</Number>
                <BackLine>
                  <ColorLine
                    style={{
                      width: percents[3],
                    }}
                  />
                </BackLine>
              </NumberLineGroup>
              <NumberLineGroup>
                <Number>2</Number>
                <BackLine>
                  <ColorLine
                    style={{
                      width: percents[2],
                    }}
                  />
                </BackLine>
              </NumberLineGroup>
              <NumberLineGroup>
                <Number>1</Number>
                <BackLine>
                  <ColorLine
                    style={{
                      width: percents[1],
                    }}
                  />
                </BackLine>
              </NumberLineGroup>
            </RatingLinesGroup>
            <AverageRate aria-label="Average raring">{averageRate}</AverageRate>
          </AverageRatingWrapper>
          <FeedbacksCount aria-label="Count of feedbacks">
            {feedbacksLengthString}
          </FeedbacksCount>
          {!!shownFeedback && <LastFeedback lastFeedback={shownFeedback} />}
          {isOrderInfoAvailable && <FeedbackForm />}
        </>
      );
    }
    if (status === 'loading') {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
    if (status === 'rejected') {
      return (
        <ErrorMessage>{staticTranslate('somethingWentWrong')}</ErrorMessage>
      );
    }
  }

  return (
    <FeedbacksWrapper aria-label="Feedback data">
      {choseShownData()}
    </FeedbacksWrapper>
  );
}
