/* eslint-disable no-console */
import axios from 'axios';

import { API_DATA } from '../constants';
import { ErrorType } from '../store/types/errors';
import { identifyError } from '../store/utils/identifyError';
import { HEADERS } from './constants';
import { ICommonResponse, IReqCubeCore } from './types';

export const request = async <T extends object | null>({
  method = 'GET',
  path,
  data,
  headers,
}: IReqCubeCore): Promise<ICommonResponse<T>> => {
  const axiosConfig = axios.create({
    baseURL: API_DATA.CUBE_CORE.url,
    headers: { ...HEADERS, ...headers },
  });

  try {
    const res = await axiosConfig.request<ICommonResponse<T>>({
      method,
      url: path,
      data,
    });

    return res.data;
  } catch (err: AnyType) {
    console.error('HTTP REQUEST ERROR');
    console.error(
      'STATUS:',
      err.response?.data?.status_code || err.response.status || 500,
    );

    if (err.response?.data) {
      console.error('REASON:', err.response.data.message || 'unknown');

      if (err.response.data.ids) {
        console.error(err.response.data.ids);
        return identifyError(err);
      }
    }
    throw new Error(ErrorType.ORDER_IN_POSTAMAT_ERROR);
  }
};
