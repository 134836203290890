import Postamat from '../../components/Postomat';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useOpenCellHandler } from '../../hooks/useOpenCellHandler';
import { useTranslate } from '../../hooks/useTranslate';

import {
  ButtonsPaper,
  CustomButton as Button,
  Main,
  Text,
  Title,
  TopWrapper,
} from './styled';

export default function CloseOpenedCells(): JSX.Element {
  const { staticTranslate } = useTranslate();

  const openCellHandler = useOpenCellHandler();

  useHeadTitle(staticTranslate('error'));
  usePageHeaderContext(staticTranslate('error'));

  function onButtonClick(): void {
    openCellHandler({ ignoreOpenCells: true });
  }

  return (
    <Main>
      <TopWrapper>
        <Title>{staticTranslate('thereAreOpenCells')}</Title>
        <Text>{staticTranslate('pleaseCoseCells')}</Text>
      </TopWrapper>
      <Postamat />
      <ButtonsPaper>
        <Button variant="outlined" onClick={onButtonClick}>
          {staticTranslate('iCantClose')}
        </Button>
        <Button variant="contained" onClick={onButtonClick}>
          {staticTranslate('iClosed')}
        </Button>
      </ButtonsPaper>
    </Main>
  );
}
