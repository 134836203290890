import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from '@omnipkg/ui-kit-web';

import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import OfferCheckbox from '../../components/OfferCheckbox';
import { ExtendSetting, projectIs } from '../../constants';
import { useNotifications } from '../../context/NotificationsContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import useScript from '../../hooks/useScript';
import { useTranslate } from '../../hooks/useTranslate';
import { Payment } from '../../projects';
import {
  selectDifference,
  selectOrderInfo,
  selectPayment,
  selectUid,
} from '../../store/slices/initialSlice';
import { Auth } from '../../store/types/orderResponse';
import { formattingString } from '../../utils/formattingString';
import { EMAIL_REGEXP } from './constants';
import { PaymentObject } from './types';

import { Form, Label, Main, TextInput } from './styled';

declare const halyk: AnyType;

export default function PaymentPage(): JSX.Element {
  const [value, setValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const { langData } = useTranslations();
  const orderInfo = useSelector(selectOrderInfo);
  const payment = useSelector(selectPayment);
  const orderUid = useSelector(selectUid);
  const difference = useSelector(selectDifference);

  const { setNotification } = useNotifications();

  const { staticTranslate } = useTranslate();

  const url = `${window.location.origin.toString()}`;

  const backLink = `${url}/#/${orderUid}/order-payment/result/success`;
  const failureBackLink = `${url}/#/${orderUid}/order-payment/result/failure`;

  const Payments =
    (ExtendSetting?.Payment as Payment[])?.length > 0
      ? (ExtendSetting.Payment as Payment[])
      : undefined;

  // TODO: check of payments setting exist
  const Settings = Payments?.find((obj) => {
    return obj.Type === 'HALYK_PAY';
  })?.Data;

  useScript(Settings?.ScriptURL as string);

  const validateEmail = (email: string): boolean => {
    const formattedEmail = formattingString(email);
    const isValidEmail = !!formattedEmail.match(EMAIL_REGEXP);
    setValue(formattedEmail);

    return isValidEmail;
  };

  const onChangeInput = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setValue(target.value);
  };

  const toPrevPage = () => {
    navigate(-1);
    window.scroll(0, 0);
  };

  const payHandler = () => {
    const { order_number: orderNumber, recipient_phone: recipientPhone } =
      orderInfo;

    const isPaymentDataAvailable =
      !!payment?.terminal && !!payment?.postLink && !!payment?.auth;

    const isValidEmail = validateEmail(value);

    if (!isValidEmail) {
      setNotification({
        status: 'error',
        value: staticTranslate('paymentErrorText'),
      });
      return;
    }

    if (!isPaymentDataAvailable) {
      setNotification({
        status: 'error',
        value: staticTranslate('somethingWentWrong'),
      });
      // eslint-disable-next-line no-console
      console.error('PAYMENT_DATA_ERROR: INVALID_PAYMENT_SETTINGS_OBJECT');
      return;
    }

    const paymentObject: PaymentObject = {
      email: value,
      auth: payment.auth as Auth,
      phone: recipientPhone,
      amount: difference,
      currency: 'KZT', // TODO: need to use receive currency
      language: langData.lang,
      cardSave: true,
      terminal: payment.terminal as string,
      backLink,
      postLink: payment.postLink as string,
      invoiceId: orderNumber,
      accountId: Settings?.AccountID,
      description: 'Услуги доставки', // TODO: need translate
      failureBackLink,
    };

    halyk.pay(paymentObject);
  };

  useHeadTitle(staticTranslate('payment'));

  function onChangeHandler(): void {
    setIsChecked((s) => !s);
  }

  const isActive = projectIs.omkaz ? isChecked : true;

  return (
    <>
      <Main>
        <Form>
          <Label htmlFor="emailInput">{staticTranslate('paymentTitle')}</Label>
          <TextInput
            id="emailInput"
            type="email"
            value={value}
            onChange={onChangeInput}
          />
        </Form>
        {projectIs.omkaz && <OfferCheckbox onChange={onChangeHandler} />}
      </Main>
      <ButtonsPaper>
        <Button variant="outlined" onClick={toPrevPage}>
          {staticTranslate('back')}
        </Button>
        <Button
          isActive={!!value && isActive}
          variant="contained"
          onClick={payHandler}
        >
          {staticTranslate('pay')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
