import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslations } from '@omnipkg/ui-kit-web';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import locationCells from '../../../../assets/images/locationOmisCells.png';
import locationCircle from '../../../../assets/images/locationOmisCircle.svg';
import { GOOGLE_MAP_API_KEY } from '../../../../constants';
import { IMapProps } from '../../../../helpers/interfaces';
import { useTranslate } from '../../../../hooks/useTranslate';
import { selectDeviceInfo } from '../../../../store/slices/initialSlice';
import { Images } from '../../../Images';
import Loader from '../../../Loader';
import { MAP_OPTIONS } from './constants';
import { useLocations } from './hooks/useLocations';

const MapGoogle = ({ width, height }: IMapProps): JSX.Element => {
  const { pathname } = useLocation();
  const deviceInfo = useSelector(selectDeviceInfo);
  const { staticTranslate } = useTranslate();
  const { langData } = useTranslations();

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    language: langData.htmlCode,
  });

  const { deviceLocation, userLocation } = useLocations();

  return !isLoaded ? (
    <Loader />
  ) : (
    <GoogleMap
      mapContainerStyle={{ width, height }}
      center={deviceLocation}
      zoom={14}
      options={MAP_OPTIONS}
    >
      {deviceLocation && (
        <Marker
          position={deviceLocation}
          icon={{
            url: pathname.includes('device-info')
              ? locationCells
              : locationCircle,
            labelOrigin: new google.maps.Point(30, 60),
            scaledSize: pathname.includes('device-info')
              ? new google.maps.Size(40, 47)
              : new google.maps.Size(23, 23),
          }}
          label={{
            text: pathname.includes('device-info')
              ? `${staticTranslate('parcelLockerNum')} ${deviceInfo.device_id}`
              : ' ',
            fontSize: '15px',
          }}
        />
      )}
      {userLocation && (
        <Marker
          position={userLocation}
          icon={{
            url: Images.Map.Geolocation,
            scaledSize: new google.maps.Size(30, 37),
          }}
        />
      )}
    </GoogleMap>
  );
};

export default MapGoogle;
