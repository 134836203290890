import { withOrientationChange } from 'react-device-detect';
import { Outlet, Route, Routes } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import { Header, ThemeContextProvider } from '@omnipkg/ui-kit-web';

import { METRICS_TYPE, METRICS_YANDEX_ID, projectIs } from '../../constants';
import { getWindowDimensions } from '../../helpers/methods';
import { usePageCheck } from '../../hooks/usePageCheck';
import { useRedirect } from '../../hooks/useRedirect';
import { useResponseInfo } from '../../hooks/useResponseInfo';
import ErrorScreen from '../../layouts/ErrorScreen';
import LandScape from '../../layouts/LandScape';
import Offer from '../../layouts/Offer';
import Footer from '../Footer';
import HeaderNav from '../HeaderNav';
import Loader from '../Loader';
import { useInitData } from './hooks/useInitData';

import { CommonContainer, ContentWrapper, TopWrapper } from './styled';

function Wrapper(): JSX.Element {
  const {
    isMainPage,
    isExtendSuccessPage,
    isToOpenPage,
    isLikeRatePage,
    isErrorPage,
    isQrScanPage,
    isWhatsNextPage,
  } = usePageCheck();

  const isShowFooter =
    isMainPage && (projectIs.omua || projectIs.omis || projectIs.omkaz);

  const { isLoading, isDeviceInfoAvailable, isOrderInfoAvailable } =
    useResponseInfo();

  const isValidOrderUid = useInitData();
  const isColorBG = isExtendSuccessPage || isToOpenPage || isLikeRatePage;
  const isHideHeaderNav =
    isErrorPage ||
    isQrScanPage ||
    isWhatsNextPage ||
    isLikeRatePage ||
    (isMainPage && !isOrderInfoAvailable) ||
    !isDeviceInfoAvailable;

  useRedirect(isValidOrderUid);

  if (
    getWindowDimensions().width > 820 &&
    !projectIs.omkaz &&
    !projectIs.omua
  ) {
    return <LandScape />;
  }

  return (
    <>
      <CommonContainer>
        <TopWrapper>
          <ThemeContextProvider>
            <Header hideNavigation />
          </ThemeContextProvider>
          {!isHideHeaderNav && <HeaderNav />}
        </TopWrapper>

        <ContentWrapper isColorBG={isColorBG}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {isDeviceInfoAvailable && <Outlet />}
              <Routes>
                <Route path="/error" element={<ErrorScreen />} />
                <Route path="/offer" element={<Offer />} />
              </Routes>
            </>
          )}
        </ContentWrapper>

        {METRICS_TYPE && (
          <div>
            {METRICS_TYPE === 'YANDEX' && (
              <YMInitializer accounts={[Number(METRICS_YANDEX_ID)]} />
            )}
          </div>
        )}
      </CommonContainer>
      {isShowFooter && <Footer />}
    </>
  );
}

export default withOrientationChange(Wrapper);
