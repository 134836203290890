import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Clusterer,
  FullscreenControl,
  Placemark,
  YMaps,
  ZoomControl,
} from 'react-yandex-maps';
import { useTranslations } from '@omnipkg/ui-kit-web';

import { IMapProps } from '../../../../helpers/interfaces/Map';
import { useTranslate } from '../../../../hooks/useTranslate';
import { selectDeviceInfo } from '../../../../store/slices/initialSlice';
import { Images } from '../../../Images';

import { YandexMap } from './styled';

const MapView = ({ width, height }: IMapProps) => {
  const [coords, setCoords] = useState<number[]>([0, 0]);
  const [mapZoom] = useState(16);

  const currentLocation = useLocation();

  const { langData } = useTranslations();
  const deviceInfo = useSelector(selectDeviceInfo);

  const { staticTranslate } = useTranslate();

  const MAP_LANG = langData.lang === 'en' ? 'en_US' : 'ru_RU';

  const mapState = {
    center: coords,
    zoom: mapZoom,
    behaviors: ['default', 'scrollZoom'],
  };

  useEffect(() => {
    const { latitude, longitude } = deviceInfo;
    setCoords([+latitude, +longitude]);
  }, [deviceInfo]);

  const defaultOptions = {
    suppressMapOpenBlock: true,
  };

  return (
    <YMaps query={{ lang: MAP_LANG }}>
      <YandexMap
        defaultState={mapState}
        modules={[
          'templateLayoutFactory',
          'layout.ImageWithContent',
          'geocode',
        ]}
        width={width}
        height={height}
        options={defaultOptions}
      >
        <FullscreenControl />
        <Clusterer
          options={{
            preset: 'islands#darkGreenClusterIcons',
            groupByCoordinates: false,
            clusterDisableClickZoom: false,
            clusterHideIconOnBalloonOpen: false,
            geoObjectHideIconOnBalloonOpen: false,
          }}
        >
          <Placemark
            geometry={coords}
            options={{
              preset: 'islands#greenIcon',
              iconLayout: 'default#imageWithContent',
              iconImageHref:
                currentLocation.pathname === '/device-info'
                  ? Images.Map.PostamatIcon
                  : Images.Map.Oval,
              iconImageSize:
                currentLocation.pathname === '/device-info'
                  ? [52, 52]
                  : [25, 25],
              iconImageOffset:
                currentLocation.pathname === '/device-info'
                  ? [-52, -52]
                  : [-25, -25],
              iconContentOffset: [-50, 50],
            }}
            properties={{
              iconContent:
                currentLocation.pathname === '/device-info'
                  ? `<span>${staticTranslate('parcelLockerNum')}${
                      deviceInfo.device_id
                    }</span>`
                  : '',
            }}
          />
        </Clusterer>
        <ZoomControl
          options={{
            size: 'small',
            visible: false,
          }}
        />
      </YandexMap>
    </YMaps>
  );
};

export default MapView;
