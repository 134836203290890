import styled from 'styled-components';

import Button from '../../components/Button';
import { infoTextTemplate } from '../../theme/pagesElements';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const TopWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  ${infoTextTemplate};
  text-align: center;
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-top: ${({ theme }): string => theme.spacing(3)};
  margin-bottom: ${({ theme }): string => theme.spacing()};
`;

export const Text = styled.h1`
  ${infoTextTemplate};
  text-align: center;
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const CustomButton = styled(Button)`
  margin: 0;
`;

export const ButtonsPaper = styled.div`
  margin-top: ${({ theme }): string => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(1.5)};
  width: 100%;
`;
