import { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import PhoneInput from '../../components/PhoneInput';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import { selectOpeningStatus } from '../../store/slices/configSlice';
import {
  selectCreatingStatus,
  selectReturnValue,
  setOrderNumber,
  setSenderPhone,
} from '../../store/slices/returnSlice';
import { Step } from '../GetOrderPage/types';
import { RETURN_FORM_STEP_KEY } from './constants';
import { useClickHandlers } from './hooks/useClickHandlers';

import { CodeInput } from '../GetOrderPage/components/CodeContainer/styled';
// Styles from get-order page
import {
  ContainersPaper,
  ContainersWrapper,
  ContentWrapper,
  Text,
} from '../GetOrderPage/styled';

export default function ReturnForm(): JSX.Element {
  const { step, setStep, nextStepHandler, prevStepHandler } =
    useClickHandlers();
  const { senderPhone, orderNumber } = useSelector(selectReturnValue);

  const isActiveBtn = step === Step.phone ? !!senderPhone : !!orderNumber;

  const dispatch = useTypedDispatch();

  const { staticTranslate } = useTranslate();

  const creatingStatus = useSelector(selectCreatingStatus);
  const openingStatus = useSelector(selectOpeningStatus);
  const isLoading = openingStatus === 'loading' || creatingStatus === 'loading';

  function setPhoneNumber(phoneNumber: string): void {
    dispatch(setSenderPhone(phoneNumber));
  }

  function setOrderNumberHandler({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void {
    dispatch(setOrderNumber(value));
  }

  useEffect(() => {
    const savedStep = sessionStorage.getItem(RETURN_FORM_STEP_KEY);
    if (savedStep) setStep(savedStep as Step);
    // Check this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePageHeaderContext(staticTranslate('returningTheParcel'));

  return (
    <>
      <ContainersWrapper>
        <ContainersPaper step={step}>
          <ContentWrapper>
            <Text>{staticTranslate('enterYourPhoneNumber')}</Text>
            <PhoneInput value={senderPhone} onChange={setPhoneNumber} />
          </ContentWrapper>
          <ContentWrapper>
            <Text>{staticTranslate('enterYourOrderNumberTo')}</Text>
            <CodeInput
              aria-label="Input for order number"
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              value={orderNumber}
              onChange={setOrderNumberHandler}
            />
          </ContentWrapper>
        </ContainersPaper>
      </ContainersWrapper>
      <ButtonsPaper>
        <Button
          isLoading={isLoading}
          isActive={isActiveBtn}
          variant="contained"
          onClick={nextStepHandler}
        >
          {staticTranslate('next')}
        </Button>
        <Button variant="outlined" onClick={prevStepHandler}>
          {staticTranslate('back')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
