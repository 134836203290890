import Button from '../../../../components/Button';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useStorageDate } from '../../../../hooks/useStorageDate';
import { useTranslate } from '../../../../hooks/useTranslate';

import { FullDate, GreatHead, Main, Message, Success } from './styled';

export default function ExtendSuccess() {
  const { expDate } = useStorageDate();

  const { staticTranslate } = useTranslate();
  const { navigateWithUid } = useCustomNavigate();

  const mainPage = () => {
    navigateWithUid('/', { replace: true });
  };

  return (
    <>
      <Main>
        <Success role="img" aria-label="Success image" />
        <GreatHead>{staticTranslate('orderExtendSuccess')}</GreatHead>
        <Message>{staticTranslate('orderExtendSuccess2')}</Message>
        <FullDate aria-label="Extension date">{expDate}</FullDate>
      </Main>
      <Button variant="outlined" onClick={mainPage}>
        {staticTranslate('main')}
      </Button>
    </>
  );
}
