import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import { cellsReducer } from './slices/cellsSlice';
import { configReducer } from './slices/configSlice';
import { feedbacksReducer } from './slices/feedbackSlice';
import { flowReducer } from './slices/flowSlice';
import { initialReducer } from './slices/initialSlice';
import { laundryReducer } from './slices/laundrySlice';
import { qrReducer } from './slices/qrSlice';
import { receiveReducer } from './slices/receiveSlice';
import { returnReducer } from './slices/returnSlice';
import { themeReducer } from './slices/themeSlice';
import { AppDispatch } from './types/store';

const persistDeviceConfig = {
  key: 'config',
  storage: sessionStorage,
};

const persistFeedbacksConfig = {
  key: 'feedbacks',
  storage: sessionStorage,
};

const persistInitialConfig = {
  key: 'initial',
  storage: sessionStorage,
};

const persistQrConfig = {
  key: 'qr',
  storage: sessionStorage,
};

const persistThemeConfig = {
  key: 'theme',
  storage: localStorage,
};

const persistReceiveConfig = {
  key: 'receive',
  storage: sessionStorage,
};

const cellsConfig = {
  key: 'cells',
  storage: sessionStorage,
};

const returnConfig = {
  key: 'return',
  storage: sessionStorage,
};

const laundryConfig = {
  key: 'laundry',
  storage: sessionStorage,
};

const persistFlowConfig = {
  key: 'flow',
  storage: sessionStorage,
};

const persistFeedbacksReducer = persistReducer(
  persistFeedbacksConfig,
  feedbacksReducer,
);
const persistInitialReducer = persistReducer(
  persistInitialConfig,
  initialReducer,
);
const persistQrReducer = persistReducer(persistQrConfig, qrReducer);
const persistThemeReducer = persistReducer(persistThemeConfig, themeReducer);
const persistConfigReducer = persistReducer(persistDeviceConfig, configReducer);
const persistReceiveReducer = persistReducer(
  persistReceiveConfig,
  receiveReducer,
);
const persistCellsReducer = persistReducer(cellsConfig, cellsReducer);
const persistReturnReducer = persistReducer(returnConfig, returnReducer);
const persistLaundryReducer = persistReducer(laundryConfig, laundryReducer);
const persistFlowReducer = persistReducer(persistFlowConfig, flowReducer);

export const rootReducer = combineReducers({
  initialReducer: persistInitialReducer,
  qrReducer: persistQrReducer,
  feedbacksReducer: persistFeedbacksReducer,
  themeReducer: persistThemeReducer,
  configReducer: persistConfigReducer,
  receiveReducer: persistReceiveReducer,
  cellsReducer: persistCellsReducer,
  returnReducer: persistReturnReducer,
  laundryReducer: persistLaundryReducer,
  flowReducer: persistFlowReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const useTypedDispatch: () => AppDispatch = useDispatch;
