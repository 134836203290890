import { useEffect, useState } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import { getOmnicTranslation } from '../utils/getOmnicTranslation';

export function useDynamicTranslate(text: string): string {
  const [value, setValue] = useState(text);

  const { langData } = useTranslations();

  useEffect(() => {
    getOmnicTranslation({ text, lang: langData.lang }).then((data) =>
      setValue(data),
    );
  }, [langData, text]);

  return value;
}
