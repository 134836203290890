import { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import Loader from '../../components/Loader';
import { projectIs } from '../../constants';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useOpenCellHandler } from '../../hooks/useOpenCellHandler';
import { useTranslate } from '../../hooks/useTranslate';
import { selectOpeningStatus } from '../../store/slices/configSlice';

import {
  A,
  CenteredVerticallyWrapper,
  InfoCDK,
  QuestionTitle,
  StyledOfferCheckbox,
} from './styled';

export default function ToOpenCell(): JSX.Element {
  const [isChecked, setIsChecked] = useState(false);

  const openingStatus = useSelector(selectOpeningStatus);
  const openCellHandler = useOpenCellHandler();

  const { staticTranslate } = useTranslate();

  const isActive = projectIs.omkaz ? isChecked : true;
  const isLoading = openingStatus === 'loading';

  useHeadTitle(staticTranslate('cellOpened'));
  usePageHeaderContext(staticTranslate('cellOpened'));

  function acceptOffer(): void {
    setIsChecked((s) => !s);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CenteredVerticallyWrapper>
        <QuestionTitle>{staticTranslate('openAsk')}</QuestionTitle>

        {projectIs.omkaz && <StyledOfferCheckbox onChange={acceptOffer} />}
        {projectIs.mncdk && (
          <InfoCDK>
            {staticTranslate('publicOffer3')}{' '}
            <A target="_blank" href="https://www.cdek.ru" rel="noreferrer">
              www.cdek.ru
            </A>
          </InfoCDK>
        )}
      </CenteredVerticallyWrapper>

      <ButtonsPaper>
        <Button isActive={isActive} variant="clear" onClick={openCellHandler}>
          {staticTranslate('cellOpen')}
        </Button>
        <Button variant="bordered" to="/">
          {staticTranslate('main')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
