import { useTranslate } from '../../../hooks/useTranslate';
import { PopupProps } from './types';

import {
  CloseIcon,
  FunctionsList,
  ListItem,
  PopupContainer,
  PopupTitle,
} from './styled';

export default function Popup({
  isVisible,
  closePopup,
}: PopupProps): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <PopupContainer isVisible={isVisible}>
      <CloseIcon
        role="button"
        aria-label="Close info popup"
        onClick={closePopup}
      />
      <PopupTitle>{staticTranslate('functions')}:</PopupTitle>
      <FunctionsList>
        <ListItem>{staticTranslate('informationAboutOrder')}</ListItem>
        <ListItem>
          {staticTranslate('informationAboutTheParcelLocker')}
        </ListItem>
        <ListItem>{staticTranslate('paymentForServices')}</ListItem>
        <ListItem>{staticTranslate('orderCollection')}</ListItem>
        <ListItem>{staticTranslate('exteningOrderStoringTime')}</ListItem>
      </FunctionsList>
    </PopupContainer>
  );
}
