import { useTranslate } from '../../../../../hooks/useTranslate';
import { FeatureId } from '../../../types';
import { NavMark } from '../types';

export function useNavMarks(): NavMark[] {
  const { staticTranslate } = useTranslate();

  return [
    {
      featureId: FeatureId.map,
      name: staticTranslate('map'),
    },
    {
      featureId: FeatureId.photo,
      name: staticTranslate('photos'),
    },
    {
      featureId: FeatureId.feedbacks,
      name: staticTranslate('feedbacks'),
    },
  ];
}
