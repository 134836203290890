import { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { selectDeviceInfo } from '../../store/slices/initialSlice';
import DeviceInfoDetails from './components/DeviceInfoDetails';
import Features from './components/Features';
import ImageSlider from './components/ImageSlider';
import Navigation from './components/Navigation';
import { FeatureId } from './types';

import { FeaturesWrapper, Main } from './styled';

export default function DeviceInfo(): JSX.Element {
  const [feature, setFeature] = useState<FeatureId>(FeatureId.map);
  const deviceInfo = useSelector(selectDeviceInfo);

  const { staticTranslate } = useTranslate();

  useHeadTitle(staticTranslate('layoutDeviceInfo'));
  usePageHeaderContext(
    `${staticTranslate('parcelLockerNum')}${deviceInfo.device_id}`,
  );

  return (
    <>
      <Main>
        <ImageSlider />
        <DeviceInfoDetails />
      </Main>
      <FeaturesWrapper>
        <Navigation setFeature={setFeature} selectedFeature={feature} />
        <Features selectedFeature={feature} />
      </FeaturesWrapper>
      <Button variant="outlined" to="/">
        {staticTranslate('main')}
      </Button>
    </>
  );
}
