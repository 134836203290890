import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { ButtonStyledProps } from './types';

const useApplyButtonStyle = ({
  variant,
  isActive,
  size,
}: ButtonStyledProps): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  const width = '94px';

  const applySize = () => {
    if (size === 'm') {
      return css`
        font-family: ${({ theme }): string =>
          theme.typography.fontFamily.font3};
        font-size: ${({ theme }): string => theme.typography.fontSize.xs};
        padding: ${({ theme: { spacing } }): string => spacing()};
        width: ${width};
      `;
    }
    if (size === 'l') {
      return css`
        font-family: ${({ theme }): string =>
          theme.typography.fontFamily.font3};
        font-size: ${({ theme }): string => theme.typography.fontSize.xs};
        padding: ${({ theme: { spacing } }): string =>
          `${spacing()} ${spacing(3)}`};
      `;
    }
    if (size === 'xl')
      return css`
        text-transform: initial;
        font-family: ${({ theme }): string =>
          theme.typography.fontFamily.font3};
        font-size: ${({ theme }): string => theme.typography.fontSize.xl};
        font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
        padding: ${({ theme: { spacing } }): string =>
          `${spacing()} ${spacing(4)}`};
        width: 100%;
      `;
    if (size === 'xxl')
      return css`
        font-family: ${({ theme }): string =>
          theme.typography.fontFamily.font2};
        font-size: ${({ theme }): string => theme.typography.fontSize.l};
        margin: 0 ${({ theme: { spacing } }): string => spacing(2)};
        width: -webkit-fill-available;
        height: 54px;
      `;
  };

  const applyStatus = () => {
    return (
      !isActive &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `
    );
  };

  const applyStyle = () => {
    switch (variant) {
      case 'outlined':
        return css`
          background-color: ${({ theme }): string =>
            theme.palette.background.secondary};
          color: ${({ theme }): string => theme.palette.text.primary};
          border: 1px solid
            ${({ theme }): string => theme.palette.border.secondary};
        `;
      case 'contained':
        return css`
          border: none;
          background-color: ${({ theme }): string => theme.palette.primary[40]};
          color: ${({ theme }): string => theme.palette.text.secondary};
        `;
      case 'bordered':
        return css`
          background-color: transparent;
          color: ${({ theme }): string => theme.palette.text.secondary};
          border: 3px solid
            ${({ theme }): string => theme.palette.border.hightLight};
        `;
      case 'clear':
        return css`
          background-color: ${({ theme }): string =>
            theme.palette.background.secondary};
          color: ${({ theme }): string => theme.palette.text.primary};
          border: none;
        `;
      case 'strong':
        return css`
          background-color: ${({ theme }): string =>
            theme.palette.background.secondary};
          color: ${({ theme }): string => theme.palette.text.primary};
          border: 1px solid ${({ theme }): string => theme.palette.text.primary};
        `;
      default:
        return css``;
    }
  };

  return css`
    ${applySize};
    ${applyStatus};
    ${applyStyle}
  `;
};

export const ButtonBase = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: fit-content;

  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${useApplyButtonStyle}
`;

export const A = styled.a`
  display: contents;
`;
